<template>
	<div class="box-fill">
		<div class="mainbox">
			<div class="box-404">
				<div class="err">4</div>
				<i class="q-s far fa-question-circle fa-spin"></i>
				<div class="err2">4</div>
			</div>

			<div class="msg">
				Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
				existed in the first place?
				<p>
					Let's go
					<b-button
						@click.prevent="redirectPage(1)"
						v-if="!user.user"
						variant="primary"
						>{{ $t('auth.sign_in') }}</b-button
					>
					<b-button @click.prevent="redirectPage(2)" v-else variant="primary"
						>{{ $t('exchange.dashboard') }}
					</b-button>
					and try from there
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters({
			user: 'userinfo/User',
		}),
	},
	methods: {
		redirectPage: function (type) {
			if (type == 1) {
				this.$router.push('/');
			} else {
				this.$router.push({ name: 'dashboard' });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap);
@media (min-width: 768px) {
	.box-fill {
		background: url('~@/assets/images/bg_ex_pc.jpg');
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
@media (max-width: 768px) {
	.box-fill {
		background: url('~@/assets/images/bg_ex_mb.png');
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
.box-fill {
	display: flex;
	align-items: center;
	min-height: 100vh;
	.mainbox {
		background-color: transparent;
		margin: auto;
		height: 600px;
		width: 600px;
		position: relative;
	}
	.box-404 {
		display: flex;
		align-items: center;
		justify-content: center;
		.err {
			color: #ffffff;
			font-family: 'Nunito Sans', sans-serif;
			font-size: 11rem;
		}

		.q-s {
			font-size: 8.5rem;
			color: #ffffff;
		}

		.err2 {
			color: #ffffff;
			font-family: 'Nunito Sans', sans-serif;
			font-size: 11rem;
		}
	}
	.msg {
		text-align: center;
		font-family: 'Nunito Sans', sans-serif;
		font-size: 1.6rem;
		position: absolute;
		left: 16%;
		top: 45%;
		width: 75%;
	}

	a {
		text-decoration: none;
		color: white;
	}

	a:hover {
		text-decoration: underline;
	}
}
</style>